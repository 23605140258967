import { Controller } from "stimulus"
import Highcharts from "highcharts"
import legendScroll from '../legend_scroll';
import defaultHighchartsConfig from "../default_highcharts_config";

export default class extends Controller {
  connect() {
    let isPDF = this.data.get("format") === "pdf";

    let config = defaultHighchartsConfig.merge({
      chart: {
        height: 300 * JSON.parse(this.data.get("series")).length,
        type: 'bar',
      },
      title: {
        text: 'Domain Scores',
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
        lineColor: 'white',
      },
      yAxis: {
        min: -0.1,
        startOnTick: false,
      },
      plotOptions: {
        series: {
          animation: !isPDF,
          pointPadding: 0.1,
          dataLabels: {
            format: '{point.y:.1f}% ({series.name})',
          },
        },
      },
      legend: {
        enabled: !isPDF
      },
      series: JSON.parse(this.data.get("series")),
    })

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    if (isPDF) {
      Highcharts.chart(this.element, config)
    } else {
      setTimeout(() => {
        Highcharts.chart(this.element, config, legendScroll)
      }, 250)
    }
  }
}
